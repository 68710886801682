<template>
  <Layout>
    <section class="csms-system">

      <!-- Page Title Start -->
      <PageHeader :title="title" />
      <!-- Page Title End -->

      <!-- Search Area Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <!-- // Search Input -->
                <div class="col-xl-10 ion-mb-mb-100">
                  <div class="row">
                    <div class="col-xl-3">
                      <label for="notification-search-title" class="form-label">{{ $t('notification.title') }}</label>
                      <b-form-input class="form-control" type="text" id="notification-search-title"
                                    v-model="searchForm.title" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="notification-search-content">{{ $t('notification.content') }}</label>
                      <b-form-input class="form-control" type="text" id="notification-search-content"
                                    v-model="searchForm.content" />
                    </div>
                    <div class="col-xl-6"></div>
                  </div>
                </div>
                <!-- // Search Button -->
                <div class="col-xl-2 align-content-end">
                  <div class="float-end">
                    <b-button variant="secondary" class="me-3" @click="reset">
                      <i class="uil uil-redo me-2"></i>
                      <span>{{ $t('btn.reset') }}</span>
                    </b-button>
                    <b-button variant="primary" @click="search">
                      <i class="uil uil-search me-2"></i>
                      <span>{{ $t('btn.search') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Search Area End -->

      <!-- List Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">
                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <PerPageSelector v-model="pagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>
                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount" />
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         show-empty :empty-text="$t('notification.noNotification')"
                         :fields="fields" :items="items">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`notification.${data.column}`) }}</span>
                  </template>

                  <template #cell(notificationType)="data">
                    {{ $_formatter.notificationTypeName(data.item.notificationType) }}
                  </template>
                  <template #cell(title)="data">
                    <a href="#" @click="viewModal(data.item.id)">{{ $_utils.textLengthOverCut(data.item.title, 40, null) }}</a>
                  </template>
                  <template #cell(content)="data">
                    {{ $_utils.textLengthOverCut(data.item.content, 60, null) }}
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- List End -->

      <view-modal ref="notificationViewModal" :selected-id="selectedId" @callback="getItems" />
    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import ViewModal from '@/views/pages/system/NotificationViewModal.vue';

export default {
  components: {
    Layout, PageHeader, PerPageSelector, TotalCount, ViewModal
  },
  data() {
    return {
      title: 'systemNotification',
      selectedId: null,
      searchConditions: [],
      sortDirection: 'DESC',
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      searchForm: {
        title: null,
        content: null
      },
      fields: [
        { key: 'notificationType', label: '타입', class: 'text-center' },
        { key: 'title', label: '제목', class: 'text-center' },
        { key: 'content', label: '내용', class: 'text-center' },
        { key: 'created', label: '등록일', class: 'text-center' }
      ],
      items: null
    }
  },
  mounted() {
    const vm = this;
    vm.getItems();
  },
  methods: {
    ...loadingMethods,
    reset() {
      const vm = this;

      vm.selectedId = null;
      vm.searchForm = {
        title: null,
        content: null
      };
      vm.searchConditions = [];

      vm.getItems();
    },
    search() {
      const vm = this;

      if (this.$_utils.isEmpty(vm.searchForm.title) && this.$_utils.isEmpty(vm.searchForm.content)) {
        Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.searchConditions'),
          confirmButtonText: i18n.t('btn.close')
        });
      } else {
        vm.searchConditions = [];
        const searchFields = Object.keys(vm.searchForm);

        searchFields.forEach(field => {
          const value = vm.searchForm[field];
          if (!this.$_utils.isEmpty(value)) {
            vm.searchConditions.push({ searchKey: field, searchValue: value });
          }
        });

        vm.pagination.currentPage = 1;
        vm.getItems();
      }
    },
    async getItems() {
      const vm = this;
      const _url = '/onmapi/view/SystemNotification/list';

      vm.showOverlay();

      await http.post(_url, {
        page: vm.pagination.currentPage,
        limit: vm.pagination.perPage,
        searchConditions: vm.searchConditions,
        sortCondition: { sortDirection: vm.sortDirection }
      }).then(res => {
        vm.items = res.data.items;
        vm.pagination.totalCount = res.data.totalCount;
      }).catch(err => {
        console.error('Notification List Error :: ', err)
      });

      vm.hideOverlay();
    },
    async onPerPageChange() {
      const vm = this;

      if (vm.pagination.totalCount > 1) {
        vm.pagination.currentPage = 1;
        await vm.getItems();
      }
    },
    async onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        await vm.getItems();
      }
    },
    viewModal(id) {
      const vm = this;
      vm.selectedId = id;

      this.$nextTick(() => {
        this.$refs.notificationViewModal.show();
      });
    }
  }
}
</script>
